<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('common.antrianManual') }}</h5>
        </div>
        <div class="modal-body">
          <input
            type="hidden"
            class="form-control"
            v-model.number="id"
          />
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">{{ $t('common.tanggal') }}:</label>
            <div class="col-lg-9">
              <date-picker
                :changedValue.sync="date"
                :id-date-picker="'dob_' + id"
                :picker-value="date"
                place-holder="DD/MM/YYYY"
                :is-validate="true"
                start-date="-0d"
                end-date=""
              ></date-picker>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">{{ $t('common.queueNo') }}:</label>
            <div class="col-lg-3">
              <input
                type="text"
                class="form-control"
                v-model="queueNo"
                v-validate="'required'"
                data-vv-as="Nomor Antrian"
                name="queueNo"
                :class="{'is-invalid': errors.has('queueNo') }"
              />
              <div
                v-show="errors.first('queueNo')"
                class="invalid-feedback"
              >{{ errors.first('queueNo') }}</div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">{{ $t('common.catatan') }}:</label>
            <div class="col-lg-9">
              <textarea
                class="form-control"
                v-model="note"
                placeholder="Catatan"
                rows="2"
              ></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="example-text-input"
              class="col-3 col-form-label"
            >{{ $t('common.queueType') }}:</label>
            <div class="col-lg-9">
              <div class="kt-radio-inline">
                <label class="kt-radio">
                  <input
                    type="radio"
                    name="queue_queue_type"
                    value="MANUAL"
                    v-model="queueType"
                    checked
                  /> MANUAL
                  <span></span>
                </label>
                <label class="kt-radio">
                  <input
                    type="radio"
                    name="queue_queue_type"
                    value="AUTO"
                    v-model="queueType"
                  /> AUTO
                  <span></span>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="example-text-input"
              class="col-3 col-form-label"
            >{{ $t('common.status') }}:</label>
            <div class="col-lg-9">
              <status-antrian
                :changedValue.sync="queueStatus"
                :selected-picker="queueStatus"
                :is-validate="true"
              ></status-antrian>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="example-text-input"
              class="col-3 col-form-label"
            >{{ $t('module.queueUnit') }}:</label>
            <div class="col-lg-9">
              <unit-antrian
                v-model="queueUnitId"
                :validation="'required'"
                :value-label="'id'"
              ></unit-antrian>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="example-text-input"
              class="col-3 col-form-label"
            >{{ $t('common.perangkatAntrian') }}:</label>
            <div class="col-lg-9">
              <perangkat-antrian
                v-model="queueDeviceId"
                :value-label="'id'"
              ></perangkat-antrian>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-clean btn-bold btn-upper btn-font-md"
            @click="clearData()"
          >{{ $t('button.close') }}</button>
          <button
            type="button"
            class="btn btn-default btn-bold btn-upper btn-font-md"
            @click="submitData()"
          >{{ $t('button.save') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusAntrian from "../../_select/StatusAntrian";
import UnitAntrian from "../../_select/UnitAntrian";
import PerangkatAntrian from "../../_select/PerangkatAntrian";
import DatePicker from "../../_general/DatePicker";
import SweetAlertTemplate from "../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const QueueUserRepository = RepositoryFactory.get("queueUser");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  components: {
    StatusAntrian,
    UnitAntrian,
    PerangkatAntrian,
    DatePicker
  },
  data() {
    return {
      id: null,
      date: "",
      queueNo: null,
      note: "",
      queueType: "MANUAL",
      queueStatus: "",
      queueUnitId: null,
      queueDeviceId: null
    };
  },
  props: {
    modalName: { type: String },
    modalData: { type: Object }
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  methods: {
    updateData: function (queueUser) {
      this.id = queueUser.id;
      this.date = queueUser.date;
      this.queueNo = queueUser.queueNo;
      this.note = queueUser.note;
      this.queueType = queueUser.queueType;
      this.queueStatus = queueUser.queueStatus;
      if (queueUser.queueUnit) {
        this.queueUnitId = queueUser.queueUnit.id;
      } else {
        this.queueUnitId = null;
      }

      if (queueUser.queueDevice) {
        this.queueDeviceId = queueUser.queueDevice.id;
      } else {
        this.queueDeviceId = null;
      }
    },
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    },
    submitData: function () {
      var vx = this;

      this.$validator.validateAll().then(result => {
        if (result) {
          var queueUnitId;
          var queueDeviceId;

          if (this.queueUnitId) {
            queueUnitId = {
              id: this.queueUnitId
            };
          } else {
            queueUnitId = null;
          }

          if (this.queueDeviceId) {
            queueDeviceId = {
              id: this.queueDeviceId
            };
          } else {
            queueDeviceId = null;
          }

          var params = {
            id: this.id,
            date: this.date,
            queueNo: this.queueNo,
            note: this.note,
            queueType: this.queueType,
            queueStatus: this.queueStatus,
            queueUnit: queueUnitId,
            queueDevice: queueDeviceId
          };

          if (this.id) {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Perubahan data Antrian Pasien akan tersimpan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.update(params);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          } else {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Data Antrian Pasien akan ditambahkan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.create(params);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          }
        }
      });
    },
    async update(payload) {
      var vx = this;

      await QueueUserRepository.updateQueueUser(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan data Antrian Pasien berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
                vx.$emit("event");
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Perubahan data Antrian Pasien gagal disimpan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    async create(payload) {
      var vx = this;

      await QueueUserRepository.createQueueUser(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data Antrian Pasien berhasil ditambahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Data Antrian Pasien gagal ditambahkan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.updateData(this.modalData);
    }
  }
};
</script>
