<template>
  <div class="kt-spinner kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input">
    <div
      class="dropdown bootstrap-select show-tick form-control"
      :class="{'is-invalid': errors.has('selectedStatus') }"
    >
      <select
        @change="changedSelectPicker"
        class="form-control"
        id="queue_status"
        v-model="selected"
        :value="selectedValue"
        data-size="5"
        :disabled="disabledSelect"
        v-validate="isValidate ? 'required' : ''"
        name="selectedStatus"
        data-vv-as="Status Antrian"
        :class="{'is-invalid': errors.has('selectedStatus') }"
      >
        <option value="NEW">New</option>
        <option value="CHECKEDIN">CheckedIn</option>
        <option value="CALLING">Calling</option>
        <option value="SERVING">Serving</option>
        <option value="DONE">Done</option>
        <option value="SKIPPED">Skipped</option>
      </select>
    </div>
    <div
      v-show="errors.first('selectedStatus')"
      class="invalid-feedback"
    >{{ errors.first('selectedStatus') }}</div>
  </div>
</template>

<script>
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const QueueDeviceRepository = RepositoryFactory.get("queueDevice");

export default {
  inject: ["$validator"],
  data() {
    return {
      dataResponse: null,
      selected: null,
      disabledSelect: true
    };
  },
  props: {
    selectedPicker: {},
    isValidate: { type: Boolean }
  },
  computed: {
    selectedValue() {
      this.selected = this.selectedPicker;
    }
  },
  methods: {
    getSelectPicker: function() {
      this.disabledSelect = false;
      $("#queue_status").selectpicker();
      this.$nextTick(() => {
        $("#queue_status").selectpicker("refresh");
      });
    },
    changedSelectPicker: function() {
      this.$emit("update:changedValue", this.selected);
    }
  },
  mounted() {
    this.getSelectPicker();
  }
};
</script>
